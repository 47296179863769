import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import GalleryList from "../gallery/GalleryList";

function MediaSection() {
  const data = useMediaData();

  return (
    <section className="section-pad">
      <div className="container">
        <h3 className="section-title text-center">
          Recent Media &amp; Press Release
        </h3>
        <GalleryList
          images={data.allMediaJson.edges[0].node.images.map(
            x => x.childImageSharp.fluid
          )}
        />
      </div>
    </section>
  );
}

const useMediaData = () =>
  useStaticQuery(graphql`
    {
      allMediaJson(sort: { fields: publishedOn, order: DESC }, limit: 1) {
        edges {
          node {
            images {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

export default MediaSection;
