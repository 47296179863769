import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BlogItem from "../blog/BlogItem";

function BlogSection() {
  const data = useStaticQuery(graphql`
    {
      allBlogJson(sort: { order: DESC, fields: created_on }, limit: 3) {
        edges {
          node {
            author
            id
            short_desc
            slug
            title
            created_on(locale: "en-IN", formatString: "LL")
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            hide_date
            hide_image
          }
        }
      }
    }
  `);

  return (
    <section className="section-pad border-bottom">
      <div className="container">
        <h3 className="section-title text-center">Blog</h3>
        <div className="row">
          {data.allBlogJson.edges.map(({ node }) => (
            <BlogItem {...node} key={node.id} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default BlogSection;
